import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import VueGtag from "vue-gtag";

import BootstrapVue from 'bootstrap-vue'
import { IconsPlugin } from 'bootstrap-vue'
import VueTilt from 'vue-tilt.js'
import VueScrollTo from 'vue-scrollto'

import Notifications from './components/Common/Notification'

Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueTilt);
Vue.use(VueScrollTo);
Vue.use(Notifications);
Vue.use(VueGtag, {
  config: { id: "G-VJWEZ7DM09" }
});


new Vue({
  render: h => h(App),
}).$mount('#app')
