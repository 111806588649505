import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=890b72d2&scoped=true&"
import script from "./Home.vue?vue&type=script&lang=js&"
export * from "./Home.vue?vue&type=script&lang=js&"
import style1 from "../assets/bootstrap/css/bootstrap.min.css?vue&type=style&index=1&id=890b72d2&scoped=true&lang=css&"
import style2 from "../assets/fonts/fontawesome-all.min.css?vue&type=style&index=2&id=890b72d2&scoped=true&lang=css&"
import style3 from "../assets/fonts/font-awesome.min.css?vue&type=style&index=3&id=890b72d2&scoped=true&lang=css&"
import style4 from "../assets/fonts/ionicons.min.css?vue&type=style&index=4&id=890b72d2&scoped=true&lang=css&"
import style5 from "../assets/fonts/fontawesome5-overrides.min.css?vue&type=style&index=5&id=890b72d2&scoped=true&lang=css&"
import style6 from "../assets/css/styles.css?vue&type=style&index=6&id=890b72d2&scoped=true&lang=css&"
import style7 from "./Home.vue?vue&type=style&index=7&id=890b72d2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "890b72d2",
  null
  
)

export default component.exports