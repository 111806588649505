<template>
  <div id="app" class="h-100 rounded">
    <Home />
  </div>
</template>

<script>
import Home from './components/Home.vue'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f8f9fa!important;
  /* margin-top: 60px; */
}


.alert {
    border-radius: 0;
}

.alert-success {
    color: #3e884f;
    background-color: rgba(#3e884f, 0.2);
    border-color: rgba(#3e884f, 0.1);
}


/* .alert-warning {
    color: $warning-color;
    background-color: rgba($warning-color, 0.2);
    border-color: rgba($warning-color, 0.1);
} */


.alert-dismissible .close {
    text-shadow: initial;
}

.alert *[data-notify="title"] {
    display: block;
    font-size: 0.9rem;
}

div[data-notify="container"] {
    padding: 18px;
}

.notification-container {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    width: 320px;
    padding: 0px 15px;
    max-height: calc(100% - 30px);
    overflow-x: hidden;
    overflow-y: auto;
}

.notification {
    box-sizing: border-box;
    padding: 15px 15px 15px 58px;
    border-radius: 2px;
    cursor: pointer;
    font-size: 1em;
    line-height: 1.2em;
    position: relative;
    opacity: 0.9;
    margin-top: 15px;
}

.notification .title {
    font-size: 1em;
    line-height: 1.2em;
    font-weight: bold;
    margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
    opacity: 1;
}

.notification-enter {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition: all 0.4s;
}

.notification-leave {
    visibility: visible;
    transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
    transition: all 0.4s;
}


.notification-success {
    color: #3e884f;
    background-color: white;
    border: 1px solid #3e884f;
    opacity: 1;
}

.notification-success .notification-message .title {
  color: #3e884f;
}

.notification-success .notification-message .message {
  color: #6c757d;
}

.notification-success .filled {
  color: white;
  background-color: #3e884f;
}

.notification-success .filled .notification-message .title {
  color: white;
}

.notification-success .filled .notification-message .message {
  color: white;
}

.notification .title {
    font-size: 1em;
    line-height: 1.2em;
    font-weight: bold;
    margin: 0 0 5px 0;
    font-family: "Nunito", sans-serif;
}




</style>
