<template>
    <div id="root" style="position:relative;">
        <div>
            <b-navbar toggleable="lg" fixed="top" class="d-flex shrink" :class="[topoPagina ? 'transparent' : 'green-glass']" id="mainNav" @scroll="handleScroll">
                <div class='ml-3 pl-3 ml-xl-5 pl-xl-5' />
                <transition name="grow-navbar" mode="out-in">
                    <div v-if='!topoPagina' class='d-flex' >
                        <b-navbar-brand href="#page-top" v-scroll-to="'#page-top'" class="justify-content-center">
                            
                            <b-container >
                                <b-row class='d-inline' align-v='end'>
                                    <b-img-lazy id="logo" src="../assets/img/shadow_logo_small.png" alt='logo-kinoa-saudavel' class="mr-3" />
                                </b-row>
                            </b-container>
                            
                        </b-navbar-brand>
                        <a id="navbar-social-link-alt" href="https://www.instagram.com/kinoa.restaurante/" class='d-block ml-0 mt-2'>
                            <i class="fa fa-instagram fa-3x"></i>
                        </a>
                    </div>
                    <a v-if='topoPagina' id="navbar-social-link-alt" href="https://www.instagram.com/kinoa.restaurante/" class='d-block ml-0'>
                        <i class="fa fa-instagram fa-3x"></i>
                    </a>
                </transition>
                <div class='mr-auto' />
                
                <b-navbar-toggle target="nav-collapse" class="mr-3"><i class="fa fa-bars"></i></b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav fluid class='ml-auto text-uppercase pl-sm-4 pr-sm-5 mr-sm-5'>

                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="ml-sm-auto text-uppercase pl-sm-4 align-items-center navbar-shadow my-3">
                        <b-nav-item href="#portfolio" v-scroll-to="'#portfolio'">CARDÁPIO</b-nav-item>
                        <b-nav-item href="#midia" v-scroll-to="'#midia'">INSTAGRAM</b-nav-item>
                        <b-nav-item href="#services" v-scroll-to="'#services'">DIFERENCIAL</b-nav-item>
                        <b-nav-item href="#about" v-scroll-to="'#about'">HISTÓRIA</b-nav-item>
                        <b-nav-item href="#team" v-scroll-to="'#team'">UNIDADES</b-nav-item>
                        <b-nav-item  href="#contact" v-scroll-to="'#contact'" class='mr-sm-4'>CONTATO</b-nav-item>
                        <b-nav-item href='https://delivery.kinoarestaurante.com.br/' class="botao-pedido"><span class='mx-2'>PEDIR</span></b-nav-item>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </div>
        <div id="nav-scroller" style="position: relative; height: 100%; overflow-y: auto">
            <header id="page-top" class="header-2">
                <div class="page-header relative" :style="{ backgroundImage: `url(${curvedBackground})` }">
                    <b-container class='py-5'>
                        <b-row align-h='center' class='py-5'>
                            <transition name="grow">
                                <div v-if='vueMounted' class='col col-9 col-sm-5 text-center mx-auto my-4 pt-3'>
                                    <b-img :src="KinoaLogo" fluid alt='logo-kinoa-saudavel'/>
                                </div>
                            </transition>
                        </b-row>
                        <b-row align-h='center' class='pb-5'>
                            <transition name="fade">
                                <b-col v-if='vueMounted' class="col-lg-10 text-center mx-auto pb-5">
                                    <h3 class="section-subheading text-muted d-none d-sm-inline mx-0">Nosso propósito é proporcionar saúde e felicidade através de uma alimentação equilibrada e muito gostosa. Nossa especialidade no delivery é a rapidez na entrega, e sempre com qualidade, devido ao nosso modelo de negócio (<em>Grab &amp; Go</em>).
                                            Trabalhamos com alimentos sem açúcares ou farinhas refinadas, tudo natural. Temos opções veganas, vegetarianas, sem glúten e sem lactose! Aqui é possível unir uma alimentação saudável, prática, de qualidade e muito sabor!</h3>
                                </b-col>
                            </transition>
                        </b-row>
                    </b-container>
                    <div class="position-absolute w-100 z-index-1 bottom-0 pt-5">
                        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40" preserveAspectRatio="none" shape-rendering="auto">
                        <defs>
                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                        </defs>
                        <g class="moving-waves">
                            <use xlink:href="#gentle-wave" x="48" y="-1" fill="rgba(248,249,250,0.40" />
                            <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(248,249,250,0.35)" />
                            <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(248,249,250,0.25)" />
                            <use xlink:href="#gentle-wave" x="48" y="8" fill="rgba(248,249,250,0.20)" />
                            <use xlink:href="#gentle-wave" x="48" y="13" fill="rgba(248,249,250,0.15)" />
                            <use xlink:href="#gentle-wave" x="48" y="16" fill="rgba(248,249,250,0.95)" />
                        </g>
                        </svg>
                    </div>
                </div>
            </header>
            <section id="portfolio" class="bg-light">
                <div class="page-header relative">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <h2 class="text-uppercase section-heading">CARDÁPIO</h2>
                                <h3 class="section-subheading text-muted">Algumas de nossas deliciosas criações, feitas com carinho pelo nosso Chef e nossa Nutricionista.</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-md-4 portfolio-item tilt" v-tilt>
                                <a class="portfolio-link" data-toggle="modal" href="https://deliverydireto.com.br/kinoa-saudaveltogo/kinoa-saudaveltogo">
                                    <div class="portfolio-hover">
                                        <div class="portfolio-hover-content"><i class="fa fa-plus fa-3x"></i>
                                            <div class="row">
                                                <div class="col"><span id="conheca-outros">FAZER PEDIDO</span></div>
                                            </div>
                                        </div>
                                    </div><img class="img-fluid" src="../assets/img/portfolio/1-thumbnail.png" alt='prato-kinoa-mignon-salada'></a>
                                <div class="portfolio-caption">
                                    <h4>Saladas</h4>
                                    <p class="text-muted">Rosbife de Mignon com Nirá e Salada</p>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 portfolio-item tilt" v-tilt>
                                <a class="portfolio-link" data-toggle="modal" href="https://deliverydireto.com.br/kinoa-saudaveltogo/kinoa-saudaveltogo">
                                    <div class="portfolio-hover">
                                        <div class="portfolio-hover-content"><i class="fa fa-plus fa-3x"></i>
                                            <div class="row">
                                                <div class="col"><span id="conheca-outros">FAZER PEDIDO</span></div>
                                            </div>
                                        </div>
                                    </div><img class="img-fluid" src="../assets/img/portfolio/2-thumbnail.png" alt='prato-kinoa-bruschetta'></a>
                                <div class="portfolio-caption">
                                    <h4>Gourmet</h4>
                                    <p class="text-muted">Trio de Bruschetta Kinoa</p>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 portfolio-item tilt" v-tilt>
                                <a class="portfolio-link" data-toggle="modal" href="https://deliverydireto.com.br/kinoa-saudaveltogo/kinoa-saudaveltogo">
                                    <div class="portfolio-hover">
                                        <div class="portfolio-hover-content"><i class="fa fa-plus fa-3x"></i>
                                            <div class="row">
                                                <div class="col"><span id="conheca-outros">FAZER PEDIDO</span></div>
                                            </div>
                                        </div>
                                    </div><img class="img-fluid" src="../assets/img/portfolio/3-thumbnail.png" alt='prato-kinoa-tilapia-spaghetti-legumes'></a>
                                <div class="portfolio-caption">
                                    <h4>Refeições</h4>
                                    <p class="text-muted">Tilápia ao Creme Siciliano com Spaghetti de Cenoura e Quinoa</p>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 portfolio-item tilt" v-tilt>
                                <a class="portfolio-link" data-toggle="modal" href="https://deliverydireto.com.br/kinoa-saudaveltogo/kinoa-saudaveltogo">
                                    <div class="portfolio-hover">
                                        <div class="portfolio-hover-content"><i class="fa fa-plus fa-3x"></i>
                                            <div class="row">
                                                <div class="col"><span id="conheca-outros">FAZER PEDIDO</span></div>
                                            </div>
                                        </div>
                                    </div><img class="img-fluid" src="../assets/img/portfolio/4-thumbnail.png" alt='prato-kinoa-quiche-sem-gluten'></a>
                                <div class="portfolio-caption">
                                    <h4>Lanches</h4>
                                    <p class="text-muted">Quiche de couve-flor recheio Frango ao Pesto <br>(low-carb e sem glúten)</p>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 portfolio-item tilt" v-tilt>
                                <a class="portfolio-link" data-toggle="modal" href="https://deliverydireto.com.br/kinoa-saudaveltogo/kinoa-saudaveltogo">
                                    <div class="portfolio-hover">
                                        <div class="portfolio-hover-content"><i class="fa fa-plus fa-3x"></i>
                                            <div class="row">
                                                <div class="col"><span id="conheca-outros">FAZER PEDIDO</span></div>
                                            </div>
                                        </div>
                                    </div><img class="img-fluid img-fluid" src="../assets/img/portfolio/5-thumbnail.png" alt='prato-kinoa-hamburguer'></a>
                                <div class="portfolio-caption">
                                    <h4>Sanduiches</h4>
                                    <p class="text-muted">Hamburguer do Chef</p>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 portfolio-item tilt" v-tilt>
                                <a class="portfolio-link" data-toggle="modal" href="https://deliverydireto.com.br/kinoa-saudaveltogo/kinoa-saudaveltogo">
                                    <div class="portfolio-hover">
                                        <div class="portfolio-hover-content"><i class="fa fa-plus fa-3x"></i>
                                            <div class="row">
                                                <div class="col"><span id="conheca-outros">FAZER PEDIDO</span></div>
                                            </div>
                                        </div>
                                    </div><img class="img-fluid" src="../assets/img/portfolio/6-thumbnail.png" alt='prato-kinoa-torta-low-carb'></a>
                                <div class="portfolio-caption">
                                    <h4>Sobremesas</h4>
                                    <p class="text-muted">Torta Low Carb</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="midia" class="bg-light">
                <div class="page-header relative">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <h2 class="text-uppercase section-heading">NOSSO INSTAGRAM</h2>
                                <h3 class="section-subheading text-muted">Fique atualizado com nossas postagens de pratos deliciosos.</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div v-for='(post,index) in posts' :key='index' class="col-sm-6 col-md-4 portfolio-item tilt" v-tilt>
                                <a class="portfolio-link" data-toggle="modal" href="" @click.prevent='navigate(post.permalink)'>
                                    <b-img-lazy class="img-fluid" :src="post.thumbnail_url ? post.thumbnail_url : post.media_url" alt='post-kinoa-restaurante'/></a>
                                <div class="portfolio-caption my-3">
                                    <small class="text-muted">{{post.caption}}</small>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row" >
                            <b-container>
                                <a href='' @click.prevent='navigate("https://www.instagram.com/kinoa.restaurante/")'>
                                    <i id="navbar-social-link-alt" class="fa fa-instagram fa-3x"></i>
                                    <p>Visite nosso Instagram</p>
                                </a>
                            </b-container>
                        </div> -->
                    </div>
                </div>
            </section>
            <section id="services">
                <div class="page-header section-height-100 relative" :style="{ backgroundImage: `url(${curvedBackground})` }">
                    <div class="position-absolute w-100 z-index-1 top-0 inverted-wave">
                        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40" preserveAspectRatio="none" shape-rendering="auto">
                        <defs>
                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                        </defs>
                        <g class="moving-waves" >
                            <use xlink:href="#gentle-wave" x="48" y="16" fill="rgba(248,249,250,0.95)" />
                            <use xlink:href="#gentle-wave" x="48" y="13" fill="rgba(248,249,250,0.15)" />
                            <use xlink:href="#gentle-wave" x="48" y="8" fill="rgba(248,249,250,0.20)" />
                            <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(248,249,250,0.25)" />
                            <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(248,249,250,0.35)" />
                            <use xlink:href="#gentle-wave" x="48" y="-1" fill="rgba(248,249,250,0.40)" />
                        </g>
                        </svg>
                    </div>

                    <div class="container py-5" id="services">
                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <h2 class="text-uppercase section-heading">DIFERENCIAL</h2>
                                <h3 class="section-subheading text-muted">Nosso objetivo é oferecer uma experiência saudável, inesquecível e prática.</h3>
                            </div>
                        </div>
                        <div class="row text-center">
                            <div class="col-md-4 zoom"><span class="fa-stack fa-4x"><i class="fa fa-circle fa-stack-2x text-primary"></i><i class="icon ion-heart fa-stack-1x fa-inverse"></i></span>
                                <h4 class="section-heading">Saúde</h4>
                                <p class="text-muted">A maior missão do Kinoa é levar mais saúde para as pessoas por meio de sua alimentação. É baseado nisso que possuimos nutricionistas que participam na elaboração e aprovação do cardápio, realizando o equilíbrio dos macronutrientes
                                    de cada prato e suas combinações.<br></p>
                            </div>
                            <div class="col-md-4 zoom"><span class="fa-stack fa-4x"><i class="fa fa-circle fa-stack-2x text-primary"></i><i class="fas fa-carrot fa-stack-1x fa-inverse"></i></span>
                                <h4 class="section-heading">Qualidade</h4>
                                <p class="text-muted">Com a visão de que a alimentação saudável pode ser muito saborosa e variada, nosso Chef de cozinha faz a elaboração dos pratos, passando por diversas culinárias nacionais e internacionais, e adaptando tudo a uma gastronomia funcional
                                    e saudável.<br></p>
                            </div>
                            <div class="col-md-4 zoom"><span class="fa-stack fa-4x"><i class="fa fa-circle fa-stack-2x text-primary"></i><i class="fas fa-shipping-fast fa-stack-1x fa-inverse"></i></span>
                                <h4 class="section-heading">Praticidade</h4>
                                <p class="text-muted">O Kinoa nasceu da dificuldade em unir uma comida saudável, gostosa e prática em um só ambiente. Foi, então, que optamos pelo To Go, dentro de um restaurante aconchegante, onde as pessoas podem escolher opções já prontas e frescas,
                                    consumi-las no próprio local ou até mesmo levá-las pra onde desejarem.</p>
                            </div>
                        </div>
                    </div>
                    <div class="position-absolute w-100 z-index-1 bottom-0">
                        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40" preserveAspectRatio="none" shape-rendering="auto">
                        <defs>
                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                        </defs>
                        <g class="moving-waves">
                            <use xlink:href="#gentle-wave" x="48" y="-1" fill="rgba(248,249,250,0.40" />
                            <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(248,249,250,0.35)" />
                            <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(248,249,250,0.25)" />
                            <use xlink:href="#gentle-wave" x="48" y="8" fill="rgba(248,249,250,0.20)" />
                            <use xlink:href="#gentle-wave" x="48" y="13" fill="rgba(248,249,250,0.15)" />
                            <use xlink:href="#gentle-wave" x="48" y="16" fill="rgba(248,249,250,0.95)" />
                        </g>
                        </svg>
                    </div>
                </div>
            </section>
            <section id="about">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <h2 class="text-uppercase">HISTÓRIA</h2>
                            <h3 class="text-muted section-subheading">Porquê e como fazemos o que fazemos.</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <ul class="list-group timeline">
                                <li class="list-group-item zoom">
                                    <div class="timeline-image"><img class="rounded-circle img-fluid" src="../assets/img/historia/brainstorm_thumb.png" alt='história-kinoa-criacao'></div>
                                    <div class="timeline-panel">
                                        <div class="timeline-heading">
                                            <h4>2018</h4>
                                            <h4 class="subheading">Ideal</h4>
                                        </div>
                                        <div class="timeline-body">
                                            <p class="text-muted">Nossa história nasceu da busca por um ambiente confortável para se ter uma alimentação saudável, prática e gostosa fora de nossas casas. Com toda a dinâmica do dia a dia nas cidades grandes, confiamos no sistema “grab
                                                and go” ou “to go” para proporcionar essa agilidade no serviço e qualidade no produto. Sempre visamos a gastronomia funcional, optando pelos produtos low carb e sem glúten, excluindo frituras, refinados e gordura
                                                hidrogenada do nosso cardápio. Buscamos ser um restaurante para todos e trazemos muitas opções vegetarianas e veganas. Queremos ser a sua opção para todas as ocasiões.</p>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item timeline-inverted zoom">
                                    <div class="timeline-image"><img class="rounded-circle img-fluid" src="../assets/img/historia/botafogo_thumb.png" alt='história-kinoa-primeira-loja'></div>
                                    <div class="timeline-panel">
                                        <div class="timeline-heading">
                                            <h4>2019</h4>
                                            <h4 class="subheading">Nasce o Kinoa</h4>
                                        </div>
                                        <div class="timeline-body">
                                            <p class="text-muted">Inauguramos o Kinoa em meados de abril e sabíamos que havia detalhes para serem ajustados de acordo com o gosto e desejo dos nossos clientes. Afinal, eles são o motivo da criação do Kinoa, que tem como objetivo facilitar
                                                a sua vida e suas escolhas saudáveis. Então, após análises e feedbacks, sempre visando a melhoria operacional, foi possível nos adaptar e chegar a uma estrutura sustentável para atender nossos clientes de forma
                                                confortável e prática, e proporcionar a melhor experiência para o nosso cliente.</p>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item zoom">
                                    <div class="timeline-image"><img class="rounded-circle img-fluid" src="../assets/img/historia/ipanema_thumb.png" alt='história-kinoa-loja-ipanema'></div>
                                    <div class="timeline-panel">
                                        <div class="timeline-heading">
                                            <h4>2020</h4>
                                            <h4 class="subheading">Primeira Expansão</h4>
                                        </div>
                                        <div class="timeline-body">
                                            <p class="text-muted">Desde a criação do Kinoa, nós sempre acreditamos no seu potencial de crescimento e na nossa vontade de levar esse conceito para cada vez mais pessoas. E com isso, surgiu a primeira oportunidade de ter nosso 2o cantinho
                                                em Ipanema.</p>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item timeline-inverted zoom">
                                    <div class="timeline-image"><img class="rounded-circle img-fluid" src="../assets/img/historia/plataforma_thumb.png" alt='história-kinoa-plataforma-digital'></div>
                                    <div class="timeline-panel">
                                        <div class="timeline-heading">
                                            <h4>2021</h4>
                                            <h4 class="subheading">Kinoa Digital</h4>
                                        </div>
                                        <div class="timeline-body">
                                            <p class="text-muted">Com a mudança repentina em nossas vidas devido à pandemia do COVID-19, decidimos lutar e colocar sonhos em ação. Desenvolvemos uma plataforma digital para trazer ainda melhor qualidade e saúdes para nossos clientes. Nela, será possível fazer pedidos programados, planos de alimentação, fidelização e benefícios, e consultas nutricionais detalhadas de todos os pratos oferecidos! Com isso trazemos o conforto e sabor de nossos pratos diretamente para o cliente final.</p>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item timeline-inverted zoom-plus">
                                    <div class="timeline-image">
                                        <h4>Faça parte <br>da nossa história</h4>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section id="team" class="bg-light">
                <div class="page-header section-height-100 relative" :style="{ backgroundImage: `url(${curvedBackground})` }">
                    <div class="position-absolute w-100 z-index-1 top-0 inverted-wave">
                        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40" preserveAspectRatio="none" shape-rendering="auto">
                        <defs>
                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                        </defs>
                        <g class="moving-waves">
                            <use xlink:href="#gentle-wave" x="48" y="16" fill="rgba(248,249,250,0.95)" />
                            <use xlink:href="#gentle-wave" x="48" y="13" fill="rgba(248,249,250,0.15)" />
                            <use xlink:href="#gentle-wave" x="48" y="8" fill="rgba(248,249,250,0.20)" />
                            <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(248,249,250,0.25)" />
                            <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(248,249,250,0.35)" />
                            <use xlink:href="#gentle-wave" x="48" y="-1" fill="rgba(248,249,250,0.40)" />
                        </g>
                        </svg>
                    </div>
                    <div class="container py-5">
                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <h2 class="text-uppercase">UNIDADES</h2>
                                <h3 class="text-muted section-subheading">Onde nos visitar.</h3>
                            </div>
                        </div>
                        <b-row class="justify-content-md-center" id="lojas">
                            <!-- <div class="col col-sm-2 col-0"></div> -->
                            <!-- <div class="col-sm-4">
                                <div class="team-member zoom">
                                    <img class="rounded-circle mx-auto" src="../assets/img/lojas/thumb-botafogo.jpg" alt='loja-kinoa-botafogo'>
                                    <h4>Botafogo</h4>
                                    <p class="text-muted">Rua Muniz Barreto, 805<br>Seg a Sáb - 10 às 22h<br>Dom - 10h às 16h<br></p>
                                    <a class="btn btn-primary" role="button" href="https://www.ifood.com.br/delivery/rio-de-janeiro-rj/kinoa---saudavel-to-go-botafogo/0e06511a-e5f4-405a-9ff0-e392cb4d99e6">
                                        PEDIR!
                                    </a>
                                </div>
                            </div> -->
                            <div class="col-sm-4">
                                <div class="team-member zoom"><img class="rounded-circle mx-auto" src="../assets/img/lojas/thumb-ipanema.jpeg" alt='loja-kinoa-ipanema'>
                                    <h4>Ipanema</h4>
                                    <p class="text-muted">Rua Aníbal de Mendonça, 55<br>Seg a Sáb - 8h às 16h</p>
                                    <a class="btn btn-primary" role="button" href="https://delivery.kinoarestaurante.com.br/">
                                        PEDIR!
                                    </a>
                                </div>
                            </div>
                            <!-- <div class="col col-sm-2 col-0"></div> -->
                        </b-row>
                    </div>
                    <div class="position-absolute w-100 z-index-1 bottom-0">
                        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40" preserveAspectRatio="none" shape-rendering="auto">
                        <defs>
                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                        </defs>
                        <g class="moving-waves">
                            <use xlink:href="#gentle-wave" x="48" y="-1" fill="rgba(248,249,250,0.40" />
                            <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(248,249,250,0.35)" />
                            <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(248,249,250,0.25)" />
                            <use xlink:href="#gentle-wave" x="48" y="8" fill="rgba(248,249,250,0.20)" />
                            <use xlink:href="#gentle-wave" x="48" y="13" fill="rgba(248,249,250,0.15)" />
                            <use xlink:href="#gentle-wave" x="48" y="16" fill="rgba(248,249,250,0.95)" />
                        </g>
                        </svg>
                    </div>
                </div>
            </section>
            <section id="contact" class="bg-light">
                <b-container>
                    <b-row class="row">
                        <div class="col-lg-12 text-center">
                            <h2 class="text-uppercase">Contato</h2>
                            <h3 class="text-muted section-subheading">Deixe seu recado</h3>
                        </div>
                    </b-row>

                    <b-row>
                        <b-col cols='12'>
                            <b-form-group>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group><input class="form-control" type="text" id="name" placeholder="Seu nome..." required="" v-model="formData.nome"><small class="form-text text-danger flex-grow-1 help-block lead"></small></b-form-group>
                                        <b-form-group><input class="form-control" type="email" id="email" placeholder="Seu e-mail" required="" v-model="formData.email"><small class="form-text text-danger help-block lead"></small></b-form-group>
                                        <b-form-group><input class="form-control" type="tel" placeholder="Seu telefone" required="" v-model="formData.telefone"><small class="form-text text-danger help-block lead"></small></b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <b-form-group><textarea class="form-control" id="message" placeholder="Deixe seu recado" required="" v-model="formData.mensagem"></textarea><small class="form-text text-danger help-block lead"></small></b-form-group>
                                    </b-col>

                                </b-row>
                                <b-row>
                                    <b-col cols="12" class="text-center">
                                        <b-button class="btn btn-primary btn-xl text-uppercase" :disabled='enviando' id="sendMessageButton" type="submit" @click="formContato">{{enviando ? 'Enviando...' : 'Enviar'}}</b-button>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-container>
            </section>
        </div>
        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-md-4"><span class="copyright">Copyright&nbsp;© Kinoa 2020</span></div>
                    <div class="col-md-4">
                        <ul class="list-inline social-buttons">
                            <li class="list-inline-item"><a href="https://www.instagram.com/kinoa.restaurante/"><i class="fa fa-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <ul class="list-inline quicklinks">
                            <li class="list-inline-item"><a href="#"></a></li>
                            <li class="list-inline-item"><a href="#"></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        <div class='mobile-order-container'>
            Olar
        </div>
    </div>
</template>

<style scoped>

</style>

<script>

import axios from 'axios';

import curvedBackground from '../assets/img/backgrounds/curved-kinoa-light.png'

import KinoaLogo from '../assets/img/logo-kinoa.svg'

const API_URL_DEV = 'http://localhost:8000/api';
const API_URL_PROD = 'https://api.kinoarestaurante.com.br/api';

const API_URL = (process.env.NODE_ENV === 'development') ? API_URL_DEV : API_URL_PROD ;

export default {
  name: 'Home',

  data() {
            return {
                curvedBackground,
                KinoaLogo,
                formData: {
                    nome: null,
                    email: null,
                    telefone: null,
                    mensagem: null
                },
                topoPagina: true,
                vueMounted: false,
                posts: [],
                enviando: false
            };
        },
  methods: {

      navigate(href) {
          window.open(href, '_blank');
      },

    formContato() {
        var vm = this;
        vm.enviando = true;
        axios.post(API_URL + '/contato/', this.formData).then(() => {
                    vm.$notify('success', 'Sucesso', 'Mensagem enviada!', { duration: 1500, permanent: false });
                    vm.enviando = false;
                });

    },
    handleScroll() {
        var currentScrollPosition = window.pageYOffset;
        if (currentScrollPosition > 300) {
            this.topoPagina = false;
        } else {
            this.topoPagina = true;
        }
    }
  },
  beforeCreate() {
      axios.get('https://graph.instagram.com/me/media?fields=id,caption,media_url,timestamp,permalink,thumbnail_url&access_token=IGQVJYMzZAmWklUdzhVODRTaXIzdmR3Tm00NkJSSUp1b2VTZAkplV3I0d1hpUWhfakdPUHhCTkFaSDhhbEtZAV0VUZAlRSUUdfanlIQjBpUW5CMFFFWGtZAT2Jac0hqbG1McnVidm5GZAm9kc0ZAmS0VEZAGZAseAZDZD').then(response => {
          this.posts = response.data.data.slice(0,6)
      })

  },
  mounted () {
    //   isLoggedIn()
    document.head.title = 'Home - Kinoa'
    this.vueMounted = true
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  beforeDestroy () {
    document.head.title = 'Outro'
  }
}
</script>

<style scoped src="../assets/bootstrap/css/bootstrap.min.css">
</style>
<style scoped src="../assets/fonts/fontawesome-all.min.css">
</style>
<style scoped src="../assets/fonts/font-awesome.min.css">
</style>
<style scoped src="../assets/fonts/ionicons.min.css">
</style>
<style scoped src="../assets/fonts/fontawesome5-overrides.min.css">
</style>
<style scoped src="../assets/css/styles.css">
</style>
<style scoped>


.navbar {
    height: 100px;
    box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
}

.wave {
  position: absolute;
  height: 23px;
  width: 100%;
  background: #b5c3a3;
  bottom: 0;
}

.wave::before, .wave::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
}

.wave::before {
  width: 55%;
  height: 109%;
  background-color: #fff;
  right: -1.5%;
  top: 60%;
}
.wave::after {
  width: 55%;
  height: 100%;
  background-color: #b5c3a3;
  left: -1.5%;
  top: 40%;
}

.page-header {
  padding: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: 50%;
}

.page-header .container {
  z-index: 1;
}

.section-height-75 {
  min-height: 75vh;
}

.section-height-100 {
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;
}

.position-absolute {
  position: absolute !important;
}

.w-100 {
  width: 100% !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.top-0 {
  top: 0 !important;
}

h3.section-subheading {
    font-size:18px;
    font-weight:400;
    font-style:italic;
    line-height: 1.5;
    margin-bottom:75px;
    text-transform:none;
    font-family:Droid Serif,Helvetica Neue,Helvetica,Arial,sans-serif
}

h2.section-heading {
    font-size:40px;
    margin-top:0;
    margin-bottom:15px
}

b-navbar{
   -webkit-transition: all 0.5s ease;
   transition: all 0.5s ease;
}

.zoom {
   -webkit-transition: all 0.5s ease;
   transition: all 0.2s ease;
}

.zoom:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.zoom-plus {
   -webkit-transition: all 0.5s ease;
   transition: all 0.2s ease;
}

.zoom-plus:hover {
  transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}



#blend-in {
  animation: fadein 0.15s;
  -moz-animation: fadein 0.15s; /* Firefox */
  -webkit-animation: fadein 0.15s; /* Safari and Chrome */
  -o-animation: fadein 0.15s; /* Opera */
  width:100%;
  height: 100%; 
  position: relative;       
  overflow: hidden;
  background-size: 75% auto;
  /* background-size: cover; */
  background-repeat: no-repeat; 
}
#contact {
  animation: fadein 0.15s;
  -moz-animation: fadein 0.15s; /* Firefox */
  -webkit-animation: fadein 0.15s; /* Safari and Chrome */
  -o-animation: fadein 0.15s; /* Opera */
  width:100%;
  height: 100%; 
  position: relative;       
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat; 
}


.waves {
  position: relative;
  width: 100%;
  height: 16vh;
  margin-bottom: -7px;
  /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

.waves.waves-sm {
  height: 50px;
  min-height: 50px;
}

.waves.no-animation .moving-waves>use {
  animation: none;
}

.wave-rotate {
  transform: rotate(180deg);
}

/* Animation for the waves */
.moving-waves>use {
  animation: move-forever 40s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.moving-waves>use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 11s;
}

.moving-waves>use:nth-child(2) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.moving-waves>use:nth-child(3) {
  animation-delay: -3s;
  animation-duration: 15s;
}

.moving-waves>use:nth-child(4) {
  animation-delay: -4s;
  animation-duration: 20s;
}

.moving-waves>use:nth-child(5) {
  animation-delay: -4s;
  animation-duration: 25s;
}

.moving-waves>use:nth-child(6) {
  animation-delay: -3s;
  animation-duration: 30s;
}

.inverted-wave {
    transform: scale(1, -1);
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}


.grow-navbar-enter-active {
  animation: grow-in 1s;
}
.grow-navbar-active {
  animation: grow-in 1s reverse;
}

.grow-enter-active {
  animation: grow-in 1s;
}
.grow-leave-active {
  animation: grow-in 1s reverse;
}
@keyframes grow-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.tilt {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.tilt .up {
  -webkit-transform: translateZ(50px);
  transform: translateZ(50px);
  transition: all 0.5s;
}

.order-button {
    background-color: rgba(199, 97, 92, 0.4);
    backdrop-filter: blur(6px) drop-shadow(30px 10px 1px rgba(199, 97, 92, 0.1));
    border-radius: 15px;
}

@media (max-width: 768px) {
    .mobile-order-container {
        position: absolute;
        bottom: 0;
        width: 100%;
        margin-top: 50px;
    }
}


.navbar-shadow {
    text-shadow: 0px 0px 8px #5a5959;
}

.botao-pedido {
    border:5px solid rgba(199, 97, 92, 1);
    border-radius:20px;
    animation: border-flicker 4s linear infinite;
}

.texto-pedido {
    animation: text-flicker 6s linear infinite;
}

@keyframes text-flicker {
  0% {
    opacity:0.1;
    
  }
  
  2% {
    opacity:1;
    
  }
  8% {
    opacity:0.1;
    
  }
  9% {
    opacity:1;
    
  }
  12% {
    opacity:0.1;
    text-shadow: 0px 0px rgba(242, 22, 22, 1);
  }
  20% {
    opacity:1;
    
  }
  25% {
    opacity:0.3;
    
  }
  30% {
    opacity:1;
    
  }
  
  70% {
    opacity:0.7;
    
  }
  
  72% {
    opacity:0.2;
    text-shadow:0px 0px 29px rgba(242, 22, 22, 1)
  }
  
  77% {
    opacity:.9;
    
  }
  100% {
    opacity:.9;
    
  }
}

@keyframes border-flicker {
  0% {
    transform:translate(0,0);
    opacity:0.1;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(199, 97, 92, 0.73);
    -moz-box-shadow: 0px 0px 20px 1px rgba(199, 97, 92, 0.73);
    box-shadow: 0px 0px 20px 1px rgba(199, 97, 92, 0.73);
  }
  1.78571% { transform:translate(1px,0) }
  3.57143% { transform:translate(0,0) }
  5.35714% { transform:translate(1px,0) }
  7.14286% { transform:translate(0,0) }
  8.92857% { transform:translate(1px,0) }
  10.71429% { transform:translate(0,0) }
  2% {
    opacity:1;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(199, 97, 92, 0.73);
    -moz-box-shadow: 0px 0px 20px 1px rgba(199, 97, 92, 0.73);
    box-shadow: 0px 0px 20px 1px rgba(199, 97, 92, 0.73);
  }
  4% {
    opacity:0.1;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(199, 97, 92, 0.73);
    -moz-box-shadow: 0px 0px 20px 1px rgba(199, 97, 92, 0.73);
    box-shadow: 0px 0px 20px 1px rgba(199, 97, 92, 0.73);
  }
  
  8% {
    opacity:1;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(199, 97, 92, 0.73);
    -moz-box-shadow: 0px 0px 20px 1px rgba(199, 97, 92, 0.73);
    box-shadow: 0px 0px 20px 1px rgba(199, 97, 92, 0.73);
  }
  70% {
    opacity:0.7;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(199, 97, 92, 0.73);
    -moz-box-shadow: 0px 0px 20px 1px rgba(199, 97, 92, 0.73);
    box-shadow: 0px 0px 20px 1px rgba(199, 97, 92, 0.73);
  }
  100% {
    transform:translate(0,0);
    opacity:1;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(199, 97, 92, 0.73);
    -moz-box-shadow: 0px 0px 20px 1px rgba(199, 97, 92, 0.73);
    box-shadow: 0px 0px 20px 1px rgba(199, 97, 92, 0.73);
  }
}

</style>
